<template lang="pug">
  .text-field(
    v-show="visible"
    ref="textField"
    :class='fieldClass'
  )
    label.text-field__label(
      v-if="label"
      :for='id'
    )
      span.text-field__label-text
        span {{ computedLabel }}
        span.required(
          v-if="required"
          title='Обязательное поле'
        ) *
        span.text-field__tooltip(
          v-if="tooltip"
        )
          Tooltip(
            :tooltip='tooltip'
          )

    .text-field__group
      input.text-field__input(
        v-if="!textarea"
        type="text"
        ref="input"
        autocomplete="off"
        :id="id"
        :value="format && !focus ? formattedValue : value"
        :placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keydown="searchOptions"
        @keyup="searchOptions"
        :readonly="readonly"
      )

      Loader(v-if="loading")

      button.text-field__arrow(
        type='button'
        v-if="optsArray.length > 0"
        @click="toggleOptions"
      )
        svg.icon(
          viewBox="0 0 9 15"
        )
          path(
            d="M1 1L7.5 7.5L1 14",
            fill="none",
            stroke="currentColor",
            stroke-width="2"
          )

      .text-field__dropdown(
        v-if="!search && optsArray.length > 0"
      )
        ul.text-field__options
          li.text-field__option(
            v-for="(option, index) in optsArray"
            :data-value="option"
            :key="index"
            :tabindex="index"
            @click="chooseOption(index, $event)"
          ) {{ option }}

      .text-field__dropdown(
        v-if="search && found.length > 0"
      )
        ul.text-field__options
          li.text-field__option(
            v-for="(option, index) in found"
            :data-value="option"
            :key="index"
            :tabindex="index"
            @click="chooseOption(index, $event)"
          ) {{ option }}

      label.text-field__errors(
        v-if="error && showError"
        :for='id'
      )
        Errors(
          :errors='[error.error]'
        )

    .text-field__legend(
      v-if="legend"
    ) {{ legend }}

</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Inputmask from "inputmask";
import Validator from "@/vendor/validate-us";
import formatNumber from "@/vendor/formatNumber";
import Tooltip from "@/components/Tooltip.vue";
import Errors from "@/components/Errors.vue";
import Loader from "@/components/Loader.vue";

import axios from "axios";
import config from "@/config.js";

import maskMixin from "@/helpers/masking/maskMixin";
import {ruPhoneNumberFormatter, getCompactPhone} from "@/helpers/masking/phoneFormatters";

function isObjectPrimitive(value) {
  return (typeof value === "object" && !Array.isArray(value) && value !== null);
}

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    kladr: {
      type: String,
      // default: "",
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: [String, Boolean],
      default: false
    },
    options: {
      type: [Array, String, Object],
      default: function() {
        return [];
      }
    },
    hiddenOptions: {
      type: [Array, String],
      default: function() {
        return [];
      }
    },
    endpoint: {
      type: [String, Boolean],
      default: false
    },
    validate: {
      type: [Object, Boolean],
      default: false
    },
    format: {
      type: [String, Boolean],
      default: false
    },
    width: {
      type: String,
      default: "full",
      validator: function(value) {
        return (
          ["full", "one-half", "one-third", "one-fourth"].indexOf(value) !== -1
        );
      }
    },
    legend: {
      type: [String, Boolean],
      default: false
    },
    textarea: {
      type: Boolean,
      default: false
    },
    action: {
      type: [Function, Boolean],
      default: false
    },
    actionIcon: {
      type: String,
      default: "menu"
    },
    customHandleChange: {
      type: [Function, Boolean],
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    mutateFields: {
      type: [Array, Boolean],
      default: false
    },
    mutateStages: {
      type: [Array, Boolean],
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    autoFormat: {
      type: [Object, Boolean],
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      focus: false,
      open: false,
      search: false,
      loading: false,
      found: []
    };
  },
  computed: {
    ...mapState("form",{
      stateForm: state => state.data,
    }),
    fieldClass() {
      return {
        "is-selectable": this.optionsLength > 0,
        "is-error": this.error,
        "is-focus": this.focus,
        "is-open": this.open,
        "is-textarea": this.textarea
      };
    },
    required() {
      if (this.validate && this.validate.required) {
        return true;
      } else {
        return false;
      }
    },
    computedLabel() {
      let parts = this.label.split("[*]");
      return parts[0];
    },
    tooltip() {
      let matches = this.label.match(/(\[\*\])(.*)(\[\/\*\])/);
      if (matches != null) {
        return matches[2];
      } else return false;
    },
    // Options of field if some passed
    opts() {
      if (this.optionsLength > 0) {
        if (typeof this.options === 'string') {
          return this.options.split(',').map((opt) => {
            return opt.trim()
          }).filter(option => !this.hiddenOptions.includes(option));
        } else {
          if (isObjectPrimitive(this.options)) {
            return Object.values(this.options).filter(
              option => !this.hiddenOptions.includes(option)
            );
          }
          else{
            return this.options.filter(
              option => !this.hiddenOptions.includes(option)
            );
          }
        }
      } else {
        return []
      }
    },
    optionsLength() {
      if (isObjectPrimitive(this.options)) {
        return  Object.keys(this.options).length;
      }
      return  this.options.length;
    },
    optsArray() {
      if (isObjectPrimitive(this.opts)) {
        return  Object.values(this.opts);
      }

      return this.opts;
    },
    // formatted value in case it needs to be formated
    formattedValue: function() {
      if (this.format && this.value.length != "") {
        if (this.format.match(/%d/)) {
          return this.format.replace("%d", formatNumber(this.value));
        } else {
          return this.format.replace("%s", this.value);
        }
      } else {
        return this.value;
      }
    },
    // Error
    error() {
      if (this.validate) {
        // если есть привязка к другому полю с датой, то добавляем к отслеживанию значение того поля
        let refDateValue;
        if (this.validate?.is?.lesserThanDate) {
          if (typeof this.stateForm[this.validate?.is?.lesserThanDate].value !== "undefined") {
            refDateValue = this.stateForm[this.validate?.is?.lesserThanDate].value
          } else {
            refDateValue = this.stateForm[this.validate?.is?.lesserThanDate]
          }
        }
        if (this.validate?.is?.greaterThanDate) {
          if (typeof this.stateForm[this.validate?.is?.greaterThanDate].value !== "undefined") {
            refDateValue = this.stateForm[this.validate?.is?.greaterThanDate].value
          } else {
            refDateValue = this.stateForm[this.validate?.is?.greaterThanDate]
          }
        }

        let error = Validator.validateField({
          value: this.value,
          validate: this.validate,
          refDateValue,
        });

        return error !== true ? error : "";
      }

      return "";
    },
    isMoneyField() {
      return this.autoFormat?.replacement === "%d.00";
    },
    isPhoneField() {
      return this.mask === 'phone_number';
    },
    ...mapGetters('form', ['fieldData']),
  },
  components: {
    Tooltip,
    Errors,
    Loader
  },
  methods: {
    ...mapMutations("form", ["setValue", "showErrors", "hideErrors", "toggleFields", "mutateField", 'toggleSteps']),
    checkAutoFormat(v) {
      if (v.length > 0 && !v.match(this.autoFormat.pattern)) {
        return this.autoFormat.replacement.replace("%d", v);
      } else {
        return v;
      }
    },
    documentClick(e) {
      let el = this.$refs.textField;
      let target = e.target;
      if (el && el !== target && !el.contains(target)) {
        this.open = false;
        this.focus = false;
      }
    },
    documentToggleOpen(e) {
      if(e.detail === this.id){
        this.open = true;
      }
    },
    handleFocus() {
      this.focus = true;
    },
    handleBlur() {
      this.focus = false
      this.changed = true
      if (this.endpoint && (!Array.isArray(this.found) || !this.found.length || (Array.isArray(this.found) && !this.found.includes(this.value)))) {
        if (this.customHandleChange) {
          this.customHandleChange({ id: this.id, value: '' })
        } else {
          this.setValue({ id: this.id, value: '' })
        }
      }
    },
    toggleOptions() {
      this.handleFocus();
      this.open ? (this.open = false) : (this.open = true);
    },
    chooseOption(chosenOptionIndex, e) {
      let nv = e.currentTarget.getAttribute("data-value");

      if (this.autoFormat) {
        nv = this.checkAutoFormat(nv);
      }

      if (this.customHandleChange) {
        this.customHandleChange({ id: this.id, value: nv });
      } else {
        this.setValue({ id: this.id, value: nv });
      }

      const fieldData = this.fieldData(this.id);
      if(fieldData && fieldData.hasOwnProperty("optionsData")){
        if(Array.isArray(fieldData.optionsData) && fieldData.optionsData.length >= chosenOptionIndex + 1){
          const fieldOptionData = fieldData.optionsData[chosenOptionIndex];
          const event = new CustomEvent("choose-option", { detail: fieldOptionData });
          this.$refs.input.dispatchEvent(event);
        }
      }

      this.toggleOptions(e);

      this.toggleFieldsIfHaveMutateFiedsList(nv);

      this.search = false;
      this.open = false;
      this.found = [];
      this.showErrors(this.id);
    },
    handleChange(e) {
      let nv = e.currentTarget.value;

      if (this.autoFormat) {
        nv = this.checkAutoFormat(nv);
      }

      if (this.endpoint && this.found.find((option) => option === nv)) {
        nv = ''
      }

      // для поля типа номер телефона
      if (this.isPhoneField) {
        nv = ruPhoneNumberFormatter(nv);
      }
      // для полей с вводом денежных единиц
      else if (this.isMoneyField) {
        nv = nv.replace(/[,юбЮБ]+/, '.')
            .replace(/[^\d\\.,]+/, '')
            .replace(/^(\d+\.\d{0,2}).*/, '$1');

        this.$refs.input.value = nv;
      }

      if (this.customHandleChange) {
        this.customHandleChange({ id: this.id, value: nv, isBlur: true });
      } else {
        this.setValue({ id: this.id, value: nv, isBlur: true });
      }

      this.toggleFieldsIfHaveMutateFiedsList(nv);

      this.showErrors(this.id);
    },
    toggleFieldsIfHaveMutateFiedsList(trigger_value){

      if (this.mutateFields) {
        this.mutateFields.map((field) => {
          const v = field.value === trigger_value;

          if (field.mutate) {
            if (v) {
              const mutateField = {...field, ...{sourceId: this.id}}
              this.mutateField(mutateField);
            }
            return;
          }

          this.toggleFields({
            fields: this.mutateFields,
            value: v || field.value,
          });

          // this.$emit('change', { id: this.id, value: this.value, event: 'change' })
        })
      }

      if(this.mutateStages){
        this.toggleSteps({
          stages: this.mutateStages,
          value: trigger_value,
        });
      }
    },
    async searchAsync() {
      if (this.value.length <= 3) return;
      this.loading = true;
      try {
        const res = await axios.post(`${config.host}${this.endpoint}`, {
          s: this.value
        });

        this.loading = false;
        this.search = true;
        this.found = res.data;
        this.open = true;
        console.log('data ==>',res.data);
        if (this.customHandleChange) {
          this.customHandleChange({ id: this.id, value: this.value });
        } else {
          this.setValue({ id: this.id, value: this.value });
        }

      } catch (e) {
        console.log(e);
        this.loading = false;
      }


    },
    searchOptions(e) {

      if (this.isPhoneField) {
        const value = e.currentTarget.value;
        const compactPhone = getCompactPhone(value);

        if (compactPhone.length >= 11 && !["Backspace", "Delete", "Alt", "Shift"].includes(e.key)) {
          e.preventDefault();
          return;
        }
      }

      if (this.nativeMask) {
        if (!["Backspace", "Delete", "Alt", "Shift"].includes(e.key)) {
          const regexp = new RegExp(this.nativeMask.config.regex);

          if (!regexp.test(e.key) || (e.target.value && !regexp.test(e.target.value + e.key))) {
            e.preventDefault();
          }
        }
      }

      if (this.isMoneyField) {
        return;
      }

      if (!(this.opts || this.endpoint)) {
        return;
      }
      let needle = e.currentTarget.value

      // Async dynamic search
      if (this.endpoint) {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.searchAsync.bind(this), 400)
      }

      // If no opts
      if (!this.optsArray.length) {
        if (this.customHandleChange) {
          this.customHandleChange({ id: this.id, value: needle })
        } else {
          this.setValue({ id: this.id, value: needle })
        }
      }

      // Filters
      if (needle.length >= 1 && this.opts && this.optsArray.length) {
        let reg = new RegExp(needle, 'i')
        let found = this.optsArray.filter((option) => {
          return option.match(reg)
        })
        if (found.length > 0) {
          if (this.customHandleChange) {
            this.customHandleChange({ id: this.id, value: needle })
          } else {
            this.setValue({ id: this.id, value: needle })
          }

          this.search = true
          this.found = found
          this.open = true
        } else {
          if (this.customHandleChange) {
            this.customHandleChange({ id: this.id, value: needle })
          } else {
            this.setValue({ id: this.id, value: needle })
          }

          this.search = false
          this.open = false
          // this.found = []
        }
      } else {
        if (this.customHandleChange) {
          this.customHandleChange({ id: this.id, value: needle })
        } else {
          this.setValue({ id: this.id, value: needle })
        }

        this.search = false
        this.open = false
        this.found = []
      }

      // if (this.isPhoneField) {
      //   const value = e.currentTarget.value;
      //   const compactPhone = getCompactPhone(value);

      //   if (compactPhone.length >= 11 && !["Backspace", "Delete", "Alt", "Shift"].includes(e.key)) {
      //     e.preventDefault();
      //     return;
      //   }
      // }

      // if (this.nativeMask) {
      //   if (!["Backspace", "Delete", "Alt", "Shift"].includes(e.key)) {
      //     const regexp = new RegExp(this.nativeMask.config.regex);

      //     if (!regexp.test(e.key) || (e.target.value && !regexp.test(e.target.value + e.key))) {
      //       e.preventDefault();
      //     }
      //   }
      // }

      // if (this.isMoneyField) {
      //   return;
      // }

      // // if (this.options.length === 0 && !this.endpoint) {
      // //   return;
      // // }

      // let needle = e.currentTarget.value;

      // // Async dynamic search
      // if (this.endpoint) {
      //   //console.log('dynamic search');
      //   clearTimeout(this.timer);
      //   this.timer = setTimeout(this.searchAsync.bind(this), 400);
      // }

      // if (this.optsArray.length == 0) {
      //   if (this.customHandleChange) {
      //     this.customHandleChange({ id: this.id, value: needle });
      //   } else {
      //     this.setValue({ id: this.id, value: needle });
      //   }
      // }



      // if (needle.length >= 1 && this.optionsLength > 0) {
      //   let reg = new RegExp(needle, "i");
      //   let found = this.optsArray.filter(option => {
      //     return option.match(reg);
      //   });

      //   if (found.length > 0) {
      //     if (this.customHandleChange) {
      //       this.customHandleChange({ id: this.id, value: needle });
      //     } else {
      //       this.setValue({ id: this.id, value: needle });
      //     }

      //     this.search = true;
      //     this.found = found;
      //     this.open = true;
      //   } else {
      //     if (this.customHandleChange) {
      //       this.customHandleChange({ id: this.id, value: needle });
      //     } else {
      //       this.setValue({ id: this.id, value: needle });
      //     }

      //     this.search = false;
      //     this.open = false;
      //     this.found = [];
      //   }
      // } else {
      //   if (this.customHandleChange) {
      //     this.customHandleChange({ id: this.id, value: needle });
      //   } else {
      //     this.setValue({ id: this.id, value: needle });
      //   }

      //   this.search = false;
      //   this.open = false;
      //   this.found = [];
      // }
    },
    // initMask() {
    //   if (this.mask || ( typeof this.mask === "object" && Object.keys(this.mask).length)) {
    //     var im = new Inputmask(this.mask);
    //     Inputmask.extendDefinitions({
    //       'd': {
    //         validator: "[0-3]"
    //       },
    //       'm': {
    //         validator: "[0-1]"
    //       },
    //       'y': {
    //         validator: "[1-2]"
    //       }
    //     });

    //     if (this.$refs.input) {
    //       im.mask(this.$refs.input);
    //     }
    //   }
    // }
  },
  created() {
    document.addEventListener("click", this.documentClick);
    document.addEventListener('toggle-options', this.documentToggleOpen);
  },
  mounted() {
    this.toggleFieldsIfHaveMutateFiedsList(this.value);
    // this.initMask();
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
    document.removeEventListener('toggle-options', this.documentToggleOpen);
  },
  mixins: [maskMixin],
};
</script>

<style lang="scss">
.text-field {
  $this: &;

  position: relative;

  &.is-open {
    #{$this}__input {
      border-color: $accent;
      border-radius: 14px 14px 0 0;
    }

    #{$this}__dropdown {
      display: block;
    }

    #{$this}__arrow .icon {
      transform: rotate(-90deg);
    }
  }

  &.is-focus {
    #{$this}__input {
      border-color: $accent;
    }
  }

  &__label {
    // display: flex;
    // align-items: center;
    // height: 33px;
    // max-width: calc(100% - 40px);
    // position: absolute;
    // left: 20px;
    // bottom: calc(100% - 16px);
    // z-index: 2;
    // background-color: $white;
    // padding-left: 10px;
    // padding-right: 10px;
    // color: $accent;
    // font-size: 15px;
    // line-height: 1;
    // word-break: break-all;

    display: block;
    margin: 0;
    color: $black;
    font-weight: $semibold;
    font-size: 21px;
    line-height: 1.23;

    .required {
      margin-left: 3px;
      color: $second;
    }
  }

  &__label-text {
  }

  &__tooltip {
    display: inline-block;
    margin-left: 7px;
    position: relative;
    top: -1px;
    color: $black;
  }

  &__group {
    min-width: 90px;
    width: 100%;
    height: 60px;
    position: relative;
    .loader {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      padding: 0;
      z-index: 2;
      height: 60px;
    }
  }

  &__label + &__group {
    margin-top: 15px;
  }

  &__input {
    width: 100%;
    padding: 17px 30px 15px;
    padding-right: 40px;
    position: relative;
    z-index: 1;
    border: 2px solid rgba(59, 123, 190, 0.5);
    border-radius: 14px;
    background-color: $white;
    color: $black;
    font-weight: $regular;
    font-size: 18px;
    outline: none !important;
    transition: ease border-color 200ms;
    text-align: left !important;

    &.is-open {
      border-radius: 14px 14px 0 0;
      border-color: $accent;
    }

    &::-moz-placeholder {
      color: $grey;
    }

    &::-webkit-input-placeholder {
      color: $grey;
    }

    &:hover {
      border-color: $accent;
    }
  }

  &__arrow {
    width: 18px;
    height: 18px;
    padding: 0;
    position: absolute;
    top: calc(50% - 6px);
    right: 25px;
    z-index: 1;
    border: none;
    background-color: transparent;
    color: $accent;
    transition: ease color 200ms;
    cursor: pointer;
    outline: none !important;

    &::before {
      content: "";
      display: block;
      width: 48px;
      height: 48px;
      position: absolute;
      left: calc(50% - 24px);
      top: calc(50% - 24px);
    }

    .icon {
      transition: ease transform 200ms;
      transform: rotate(90deg);
    }
  }

  &__dropdown {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    z-index: $layer-2;
    border: 2px solid $accent;
    border-top: none;
    border-radius: 0px 0px 14px 14px;
    box-shadow: none;
    background-color: $white;
    overflow: hidden;
  }

  &__options {
    max-height: 350px;
    width: 100%;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    text-align: left;
    overflow: auto;
  }

  &__option {
    padding: 18px 30px 14px;
    color: $black;
    font-weight: $regular;
    font-size: 18px;
    line-height: 1.2;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $accent;
    }

    &.is-selected {
      color: $second;
      background-color: transparent;
      pointer-events: none;
    }
  }

  &__errors {
    display: block;
    width: 100%;
    position: absolute;
    top: calc(100% + 7px);
    left: 0;
    z-index: $layer-1;
  }

  &__legend {
    margin-top: 10px;
    color: $grey;
    font-size: 14px;
  }


}
</style>
